define(["dojo-proxy-loader?name=dojo/_base/kernel!/home/mcdeploy/mc_node_modules_cache/f5c937001936868c95650e74637f4926d35c3986/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (dojo) {
  // module:
  //		dijit/main

  /*=====
  return {
  	// summary:
  	//		The dijit package main module.
  	//		Deprecated.   Users should access individual modules (ex: dijit/registry) directly.
  };
  =====*/

  return dojo.dijit;
});