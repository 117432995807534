/**
 * Mixin for adding style rules to a custom stylesheet
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/f5c937001936868c95650e74637f4926d35c3986/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/sniff!/home/mcdeploy/mc_node_modules_cache/f5c937001936868c95650e74637f4926d35c3986/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (declare, has) {
  return declare([], {
    createStyleNode: function (doc, id) {
      var node;
      // check for id, and if node already exists
      if (id) {
        var selectedNode = doc.getElementById(id);
        if (selectedNode) {
          return selectedNode;
        }
      }
      node = document.createElement("style");
      node.type = "text/css";
      node.setAttribute("id", id ? id : "");
      doc.getElementsByTagName("head")[0].appendChild(node);
      return node;
    },
    getStyleSheet: function (node) {
      var sheet;
      if (has("ie") < 9) {
        sheet = node.styleSheet;
      } else {
        // WebKit hack :(
        node.appendChild(document.createTextNode(""));
        sheet = node.sheet;
      }
      this.addCSSRule(sheet, "body", "width:100%;height:100%;", 0); // Add a rule to avoid index errors later
      return sheet;
    },
    addCSSRule: function (sheet, selector, rules, index) {
      index = typeof index !== 'undefined' ? index : "cssRules" in sheet ? sheet.cssRules.length : sheet.rules.length; // rules for IE < 9, cssRules for everyone else
      if ("insertRule" in sheet) {
        sheet.insertRule(selector + "{" + rules + "}", index);
      } else if ("addRule" in sheet) {
        sheet.addRule(selector, rules, index);
      }
    }
  });
});